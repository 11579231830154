<i18n>
{
  "en": {
    "title": "Settings",
    "workflows": "Pipelines",
    "workflows-description": "Set up pipelines and stages as your needs",
    "custom-fields": "Custom Fields",
    "custom-fields-description": "Set up custom fields for workflows and external forms",
    "products": "Products",
    "products-description": "Set up products or services of your business",
    "task-types": "Task Types",
    "task-types-description": "Set up different types of sales activities",
    "webforms": "Web Forms",
    "webforms-description": "Set up web forms and integrate with your workflows",
    "tracker": "Tracker",
    "tracker-description": "Open card tracking portal"
  },
  "pt-BR": {
    "title": "Configurações",
    "workflows": "Pipelines",
    "workflows-description": "Configure kanbans com estágios customizados",
    "custom-fields": "Campos Customizados",
    "custom-fields-description": "Configure os campos dos formulários de cadastro",
    "products": "Produtos",
    "products-description": "Configure produtos ou serviços do seu negócio",
    "task-types": "Tipos de Tarefas",
    "task-types-description": "Configure diferentes tipos de atividades de venda",
    "webforms": "Formulários Web",
    "webforms-description": "Configure um formulário e integre com seus pipelines",
    "tracker": "Rastreador",
    "tracker-description": "Portal de acompanhamento de cards abertos"
  }
}
</i18n>

<template>
  <div id="configurations">
    <nav-top :title="$t('title')"> </nav-top>
    <board-config>
      <template #config-content>
        <div class="config-grid">
          <we-config-item
            v-for="item in filteredItems"
            :key="item.routeName"
            :item="item"
          />
        </div>
      </template>
    </board-config>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configItems: [
        {
          routeName: "pipelinesConfigs",
          icon: "columns",
          name: this.$t("workflows"),
          description: this.$t("workflows-description")
        },
        {
          routeName: "customFieldsConfigs",
          icon: "folder-plus",
          name: this.$t("custom-fields"),
          description: this.$t("custom-fields-description")
        },
        {
          routeName: "productsConfigs",
          icon: "cubes",
          name: this.$t("products"),
          description: this.$t("products-description")
        },
        {
          routeName: "tasksConfigs",
          icon: "tasks",
          name: this.$t("task-types"),
          description: this.$t("task-types-description")
        },
        {
          routeName: "webformsConfigs",
          icon: "file-lines",
          name: this.$t("webforms"),
          description: this.$t("webforms-description")
        },
        /*{
          routeName: "returns",
          icon: "truck",
          name: this.$t("returns"),
          description: this.$t("returns-description")
        },*/
        {
          routeName: "https://tracker.wepipe.com.br",
          icon: "magnifying-glass",
          name: this.$t("tracker"),
          description: this.$t("tracker-description")
        }
      ]
    };
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
    filteredItems() {
      return this.configItems.filter(e => {
        if (!e.beta || e.beta.length <= 0) return true;

        if (e.beta.includes(this.domain)) return true;

        return false;
      });
    }
  }
};
</script>

<style lang="scss">
#configurations {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
